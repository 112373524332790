export default {
  home: {
    minute: "Minute",
    price_info:
      "{mianfei} minutes free, maximum per day  {currencyName}{fengding}, deposit {currencyName}{yajin},  free rental deposit",
    submit: "Aufladen",
    borrow: "Ausleihen",
    msg_rentcount: "Sie haben derzeit {zujieNum} Bestellungen, die nicht abgeschlossen sind",
    cancel: "Stornieren",
    confirm: "Bestätigen",
    msg_fail_title: "Fehler",
    msg_fail_content: "Die Miete ist fehlgeschlagen. Bitte versuchen Sie es erneut",
    offline:
      "The device {devicecode} is offline, please contact the staff present",
    permissionfail: "Zugriff verweigert.",
    unbound: "Der Mix-QR-Code {devicecode} ist nicht an das Gerät gebunden",
    nobattery: "Im Gerät {devicecode} ist kein Akku verfügbar.",
    next: "NÄCHSTE",
    renttext: "Mieten Sie eine Powerbank."
  },
  loginborrow: {
    cCard: 'Kreditkarte',
    perhour: 'Stunde',
    maxperday: 'Tag',
    preauth: "Vorautorisierung",
    enterpromocode: "Geben Sie den Aktionscode ein",
    powerbanktitle: "Melden Sie sich an, um Ihre Powerbank zu entsperren",
    msg_fail_title: "Fehler",
    msg_fail_user: "Fehler beim Abrufen der Benutzerinformationen. Willst du es nochmal versuchen?",
    msg_rentcount: "Sie haben derzeit {zujieNum} Bestellungen, die nicht abgeschlossen sind",
    msg_fail_support: "Das System wird nicht unterstützt",
    msg_fail_confirm: "Bestätigen",
    msg_fail_retry: "Wiederholen",
    msg_fail_cancel: "Stornieren",
    msg_fail_order: "Mietauftrag konnte nicht erstellt werden",
    msg_fail_paymod: "Zahlungsmethode konnte nicht abgerufen werden",
    msg_fail_login: "Fehler bei der Anmeldung",
    Apple: "Apfel",
    otheropt: "Andere Optionen",
    selectmethod: "Wählen Sie eine Bezahlart",
    paymentdetail: "Details zur Zahlungsart",
    couponcode: "Gutscheincode",
    rent: "Bestätige Zahlung",
    pricing: "Siehe Preise",
    phone: "Telefon",
    Google: "Google",
    Facebook: "Facebook",
    loginfb: "Mit Facebook anmelden",
    logingg: "Anmeldung mit Google",
    captcha_code: "Code",
    phonenumber: "Gib deine Telefonnummer ein",
    msg_fail_code: "Bitte den Bestätigungscode eingeben",
    msg_fail_phone: "Bitte geben sie ihre Telefonnummer ein",
    logout: "Konto verlassen",
    savenow: "Jetzt sparen",
    paynow: "Bezahlen Sie jetzt",
    delete: "Löschen",
    addcard: "Geben Sie die Kartendetails ein",
    msg_fail_content: "Die Miete ist fehlgeschlagen. Bitte versuchen Sie es erneut",
    title: "Bitte bestätigen Sie die Autorisierung",
    policy: "Politik.",
    term: "  Geschäftsbedingungen",
    and: "Und",
    privacy: "Datenschutzrichtlinie.",
    tips: "Indem Sie fortfahren, stimmen Sie unserem zu",
    cancel: "STORNIEREN",
    agree: "ZUSTIMMEN",
    balance: "Gleichgewicht"
  },
  preauth: {
    preauthortitle: "Über die Vorautorisierung",
    tip1:
      "When starting a rental, a pre-authorisation is placed on your payment method. This is just to ensure there are sufficient funds available to pay for your rental.",
    tip2:
      "Your bank may send you a notification but it is not a charge: only the price of your rental will be charged.",
    tip3:
      "Depending on your bank, it can take a few hours up to a few days for the amount to be adjusted on your statements, this is normal."
  },
  rentcost: {
    free: 'Freiminuten 3min',
    per: 'pro',
    min: 'Mindest',
    charged: 'Es wird Ihnen eine Gebühr berechnet',
    rentcosttitle: "Wie viel kostet die Miete?",
    tip1:
      "If you do not return the power bank within 48 hours you will be charged a non-return fee of {currencyName} {yajin} .",
    tip2: "Die Dauer wird auf die nächste halbe Stunde (30 oder 60 Minuten) gerundet.",
    tip3: "Die Miete für einen Tag beträgt 24 Stunden ab dem Zeitpunkt der Anmietung der Powerbank.",
    tip3_1:
      "A pre-authorisation fee of {currencyName} {cyajin} will be placed on your payment method. This is to ensure there are sufficient funds available to pay for your rental.",
    tip3_2:
      "Your bank may send you a notification but it is not a charge; only the price of your rental will be charged.",
    tip3_3:
      "Depending on your bank, it can take a few hours, up to a few days for the amount to be adjusted on your statements.",
    tip4: "Einige Veranstaltungsorte bieten möglicherweise kostenlose Stunden oder Ermäßigungen an. Genießen!",
    Days: "Tage",
    Hours: "Std",
    Minutes: "Protokoll"
  },
  logincode: {
    login: "Anmeldung",
    retry: "Erneut senden",
    title: "Bestätigungscode",
    subtitle: "Bitte geben Sie den Bestätigungscode ein, den wir an Ihre Telefonnummer gesendet haben."
  },
  agreenconfirm: {
    title: "Bitte bestätigen Sie die Autorisierung",
    policy: "Politik.",
    term: "Allgemeine Geschäftsbedingungen von PowerBox",
    and: "Und",
    privacy: "Datenschutzrichtlinie.",
    tips: "Ich stimme dem zu",
    cancel: "STORNIEREN",
    agree: "ZUSTIMMEN"
  },
  login: {
    phonenumber: "Telefonnummer",
    verificationcode: "Bestätigungscode",
    Rentpowerbank: "Mieten Sie eine Powerbank",
    Rentpowerbanktip: "Geben Sie Ihre Handynummer ein, um zu beginnen",
    phone_number: "Bitte geben Sie eine {0}-stellige Mobiltelefonnummer ein",
    captcha_code: "Code",
    send_code: "Code senden",
    login: "Anmeldung",
    agree: "Indem Sie auf „Anmelden“ klicken, stimmen Sie dem zu",
    readme: `User Agreement`,
    msg_fail_login: "Fehler bei der Anmeldung",
    msg_success_login: "Anmeldung erfolgreich",
    msg_success_login_code:
      "Successfully received, please download the APP to log in to use",
    msg_fail_user: "Fehler beim Abrufen der Benutzerinformationen. Willst du es nochmal versuchen?",
    no_sms: "Keine SMS erhalten",
    retry: "Erneut senden",
    receive: "Anwenden",
    invite_pwd: "Einladungscode",
    vip_agreen_tip: "Indem Sie fortfahren, erklären Sie, dass Sie unsere akzeptieren",
    vip_agreen_service: "Nutzungsbedingungen",
    vip_agreen_and: "Und",
    vip_agreen_policy: "Datenschutzrichtlinie"
  },
  confirm: {
    title: "Zahlungsbestätigung",
    pay_label: "Menge",
    addcard: "Wallet-Karte hinzufügen",
    price_tips:
      "Click to confirm payment, indicating that you have read and agreed to the ",
    price_btn: "„Mehrwertvereinbarung“",
    pay_type: "Bitte wählen Sie Ihre bevorzugte Zahlungsmethode aus",
    submit: "Mietvertrag bestätigen",
    charge: "Bestätigen Sie das Aufladen",
    msg_fail_title: "Warnung",
    msg_fail_order: "Mietauftrag konnte nicht erstellt werden",
    msg_fail_confirm: "Bestätigen",
    msg_fail_retry: "Wiederholen",
    msg_fail_cancel: "Stornieren"
  },
  mine: {
    title: "Benutzerprofil",
    wallet: "Mein Geldbeutel",
    balance: "Mein Gleichgewicht",
    mywalletcard: "Meine Karte",
    addcard: "Wallet-Karte hinzufügen",
    deposit: "Kaution",
    wallet_in: "Aufladung",
    wallet_out: "Zurückziehen",
    wallet_log: "Wallet-Protokoll",
    order: "Befehl",
    login_tips: "Bitte loggen Sie sich ein",
    msg_fail_title: "Fehler",
    msg_fail_user: "Fehler beim Abrufen der Benutzerinformationen. Willst du es nochmal versuchen?",
    help: "Helfen",
    setting: "Einstellung",
    logout: "Ausloggen",
    unpaid: "Unbezahlt",
    using: "In Benutzung"
  },
  msg: {
    oneOrdering:
      "1 order is in progress, and the lease cannot be continued for the time being.",
    nopaymode: "Der Erwerb der Zahlungsmethode ist fehlgeschlagen",
    unlogin: "Sie sind noch nicht angemeldet, bitte melden Sie sich an",
    msg_fail_title: "Warnung",
    deletecard: "Möchten Sie diese Karte wirklich löschen?",
    loading: "Wird geladen...",
    title: "Tipps",
    success: "Erfolgreiche Operation",
    error: "Unbekannter Fehler, bitte versuchen Sie es später noch einmal.",
    userinfo_fail: "Benutzerinformationen ungültig, bitte melden Sie sich erneut an",
    roleerror: "Unzureichende Berechtigungen, wenden Sie sich bitte an den Administrator",
    neterror: "Netzwerkverbindung fehlgeschlagen. Bitte versuchen Sie es später noch einmal",
    confirm: "Bestätigen",
    retry: "Wiederholen",
    cancel: "Stornieren",
    msg_logout: "Möchten Sie das Konto wirklich verlassen?",
    vieworder: "Bestellung ansehen",
    addorselectcard: "Bitte fügen Sie ein Kartenpaket hinzu oder wählen Sie ein Kartenpaket aus, um fortzufahren",
    success_payment: "Bezahlvorgang abgeschlossen",
    fail_payment: "Zahlungsfehler",
    keyword: "Stichwort",
    unpaid_order: "Es liegen unbezahlte Bestellungen vor",
    go_pay: "Geh und bezahle",
    no_more: "nicht mehr",
    noshop: "Keine Händler",
    msg_fail_code: "Bitte den Bestätigungscode eingeben",
    msg_fail_phone: "Bitte geben sie ihre Telefonnummer ein",
    msg_fail_orderid: "Bitte geben Sie die Bestellnummer ein",
    msg_fail_email: "E-Mail darf nicht leer sein",
    exceedorder:
      "You already have an order under lease, please return it before continuing",
    nodevicecode: "Gerätecode existiert nicht",
    appoffline: "Die App wurde noch nicht gestartet. Bitte warten Sie",
    payment_unsupported: "Diese Zahlungsmethode wird nicht unterstützt",
    msg_fail_balance: "Unzureichendes Benutzerguthaben",
    msg_fail_order_balance: "Die Bestellung ist nicht vorhanden oder der Saldo reicht nicht aus."
  },
  result: {
    title: "Powerbank freigeschaltet!",
    tips: "Ihre Powerbank ist jetzt bereit, sodass Sie sie unterwegs aufladen können!",
    download: "Herunterladen",
    msg_result_loading: "Das Zahlungsergebnis wird bestätigt...",
    msg_tips_loading:
      "Portable battery is popping up, please wait patiently...",
    haveorder: "Offizieller Auftrag wurde generiert"
  },
  result_msg: {
    loading: "Wird geladen...",
    refresh: "Aktualisierung"
  },
  order: {
    title: "Mietauftrag",
    in_use: "In Benutzung",
    ended: "Beendet",
    msg_error: "Anfrage fehlgeschlagen",
    no_data: "Keine Daten"
  },
  order_item: {
    order_number: "NEIN.",
    venue_rented: "Veranstaltungsort gemietet",
    time_rented: "Zeit gemietet",
    venue_returned: "Veranstaltungsort zurückgegeben",
    time_returned: "Die Zeit kehrte zurück",
    charge_standard: "Ladestandard",
    rent_occurred: "Es kam zur Miete",
    returned: "Ist zurückgekommen",
    unreturned: "Nicht zurückgegeben",
    type_0: "Nicht vollständig",
    type_1: "Vermietung",
    type_2: "Abgesagt",
    type_3: "Ist zurückgekommen",
    type_4: "Es ist ein Fehler aufgetreten",
    type_5: "Im Laufe der Zeit",
    min: "Mindest",
    unpaid: "Unbezahlt",
    payment: "Zahlung"
  }
};
