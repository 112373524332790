import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=41b3a2d8"
var script = {}
import style0 from "./loading.vue?vue&type=style&index=0&id=41b3a2d8&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports