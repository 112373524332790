export default {
  home: {
    minute: '分钟',
    price_info: '{mianfei}分钟内免费，日封顶{currencyName}{fengding}，押金{currencyName}{yajin}，支持免押金租借',
    submit: '立即充值',
    borrow: '立即租借',
    msg_fail_title: '提示',
    msg_fail_content: '租借失败，请重新尝试',
    msg_rentcount: '你当前有{zujieNum}个订单未结束',
    cancel: '取消',
    confirm: '确定',
    offline: '设备 {devicecode} 离线，请与在场人员联系',
    permissionfail: '权限被拒绝。',
    unbound: '二维码 {devicecode} 未绑定到设备',
    nobattery: '设备｛devicecode｝中没有可用的电池',
    next: '下一步',
    renttext: '租借电池'
  },
  loginborrow: {
    cCard: '信用卡',
    preauth: '预授权',
    enterpromocode: '输入券码',
    msg_fail_title: '提示',
    msg_fail_user: '获取用户信息出错，是否重试？',
    msg_rentcount: '你当前有{zujieNum}个订单未结束',
    msg_fail_support: '当前环境不支持',
    msg_fail_confirm: '确定',
    msg_fail_retry: '重试',
    msg_fail_cancel: '取消',
    msg_fail_order: '创建租借订单失败',
    msg_fail_paymod: '获取支付方式失败',
    msg_fail_login: '登录失败',
    powerbanktitle: '解锁PowerBox充电宝',
    Apple: 'Apple',
    otheropt: '其他方式',
    selectmethod: '请选择支付方式',
    paymentdetail: '付款方式详情',
    couponcode: '优惠码',
    rent: '租借',
    pricing: '查看价格',
    phone: '手机号',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: '使用 Facebook 登录',
    logingg: '使用 Google 登录',
    captcha_code: '验证码',
    phonenumber: '请输入你的手机号',
    msg_fail_code: '请输入验证码 ',
    msg_fail_phone: '请输入您的电话号码',
    logout: '退出账号',
    savenow: '立即保存',
    "paynow": "立即付款",
    delete: '删除',
    addcard: '卡包',
    msg_fail_content: '租借失败，请重新尝试',
    title: '请确认授权',
    policy: 'Policy.',
    term: '条款和条件',
    and: '以及',
    privacy: '隐私政策.',
    tips: '继续，则您同意我们的',
    cancel: '取消',
    agree: '同意',
    balance: '余额'
  },
  preauth: {
    preauthortitle: '关于预授权',
    tip1: '开始租赁时，您的付款方式会得到预先授权。这只是为了确保有足够的资金来支付您的租金。',
    tip2: '您的银行可能会向您发送通知，但这不是收费：只会收取您的租金。',
    tip3: '根据您的银行，可能需要几个小时到几天的时间才能在您的对账单上调整金额，这是正常的。'
  },
  rentcost: {
    rentcosttitle: '租金是多少？',
    tip1: '如果您在2天内没有归还充电宝，除了租赁费外，还将向您收取{currencyName} {yajin} 的归还费。',
    tip2: '持续时间四舍五入到最接近的半小时（30或60分钟）。',
    tip3: '1天租金从租赁开始算起24小时。',
    tip3_1: '您的付款方式将收取{currencyName} {cyajin}的预授权费。这是为了确保有足够的资金支付租金',
    tip3_2: '您的银行可能会向您发送通知，但这不是收费，只收取租金',
    tip3_3: '根据您的银行，可能需要几个小时，最多几天的时间才能在您的对账单上调整金额',
    tip4: '一些场馆可能会提供一些免费时间或折扣',
    Days: '天',
    Hours: '小时',
    Minutes: '分钟'
  },
  logincode: {
    login: '登录',
    retry: '重试',
    title: '验证码',
    subtitle: '请输入我们发送给您的电话号码的验证码'
  },
  agreenconfirm: {
    title: '请确认授权',
    policy: 'Policy.',
    term: 'PowerBox的条款和条件',
    and: '以及',
    privacy: '隐私政策.',
    tips: 'l agree to ',
    cancel: '取消',
    agree: '同意'
  },
  login: {
    phonenumber: '手机号',
    verificationcode: '验证码',
    Rentpowerbank: '租借充电宝',
    Rentpowerbanktip: '输入您的手机号码以开始',
    phone_number: '请输入{0}位数手机号码',
    captcha_code: '验证码',
    send_code: '获取验证码',
    login: '登录',
    agree: '点击登录，表示已阅读并同意',
    readme: '使用条款',
    msg_fail_login: '登录失败',
    msg_success_login: '登录成功',
    msg_success_login_code: '領取成功，請下載APP登錄使用',
    msg_fail_user: '获取用户信息出错，是否重试？',
    no_sms: '没收到短信',
    retry: '重新发送',
    receive: '申请领取',
    invite_pwd: '邀请验证码',
    vip_agreen_tip: '继续，表示您接受我们的',
    vip_agreen_service: '服务条款',
    vip_agreen_and: '和',
    vip_agreen_policy: '隐私政策'
  },
  confirm: {
    title: '确认付款',
    pay_label: '需支付',
    addcard: '添加卡包',
    price_tips: '点击确认付款，表示已阅读并同意《增值协议》',
    pay_type: '请选择支付方式',
    submit: '确认租借',
    charge: '确认充值',
    msg_fail_title: '提示',
    msg_fail_order: '创建租借订单失败',
    msg_fail_confirm: '确定',
    msg_fail_retry: '重试',
    msg_fail_cancel: '取消'
  },
  mine: {
    title: '个人中心',
    wallet: '我的钱包',
    balance: '我的余额',
    addcard: '添加卡包',
    deposit: '押金',
    wallet_in: '充值',
    wallet_out: '提现',
    wallet_log: '充值提现',
    order: '租借订单',
    login_tips: '请先登录',
    msg_fail_title: '提示',
    msg_fail_user: '获取用户信息出错，是否重试？',
    help: '帮助',
    setting: '设置',
    logout: '退出',
    unpaid: '未繳納',
    using: '使用中'
  },
  msg: {
    oneOrdering:
      "1订单正在进行中，暂时无法继续进行租约。",
    nopaymode: '支付方式获取失败',
    unlogin: '你还未登录，请登录',
    msg_fail_title: '提示',
    deletecard: '确认删除该卡吗？',
    loading: '加载中...',
    title: '提示',
    success: '操作成功',
    error: '未知异常，请联系管理员',
    userinfo_fail: '用户信息失效，请重新登录',
    roleerror: '权限不足，请咨询管理员',
    neterror: '网络连接失败，请稍后再试',
    confirm: '确认',
    retry: '重试',
    cancel: '取消',
    msg_logout: '确认退出账号？',
    addorselectcard: '请添加或选择一个卡包后继续',
    vieworder: '查看订单',
    success_payment: '充值成功',
    fail_payment: '付款失败',
    keyword: '关键词',
    unpaid_order: '有未付款的订单',
    go_pay: '去支付',
    no_more: '沒有更多了',
    noshop: '暂无商户',
    msg_fail_code: '请输入验证码',
    msg_fail_phone: '请输入手机号',
    msg_fail_orderid: '请输入订单号',
    msg_fail_email: '邮箱不能为空',
    exceedorder: '你已经有租借中订单，请归还后继续',
    nodevicecode: '设备code不存在',
    appoffline: 'APP暂时还未上线，请等待',
    payment_unsupported: '该付款方式不支持',
    msg_fail_balance: '余额不足',
    msg_fail_order_balance: '订单不存在或余额不足'
  },
  result: {
    title: '充电宝解锁',
    tips: '你的充电宝现在已经准备好了，所以你可以随时随地充电。',
    download: '下载',
    msg_tips_loading: '正在弹出充电宝，请耐心等待...',
    msg_result_loading: '正在确认支付结果...',
    haveorder: '正式订单已生成'
  },
  result_msg: {
    loading: '加载中...',
    refresh: '点击刷新'
  },
  order: {
    title: '租借订单',
    in_use: '正在使用',
    ended: '已结束',
    msg_error: '加载失败',
    no_data: '暂无数据'
  },
  order_item: {
    order_number: '订单号',
    venue_rented: '租借地点',
    time_rented: '租借时间',
    venue_returned: '归还地点',
    time_returned: '归还时间',
    charge_standard: '收费标准',
    rent_occurred: '租借费用',
    returned: '已归还',
    unreturned: '未归还',
    type_0: '未租借',
    type_1: '租借中',
    type_2: '已撤销',
    type_3: '已归还',
    type_4: '有疑问',
    type_5: '超时归还',
    min: '分',
    unpaid: '未付款',
    payment: '付款'
  }
}
