export default {
  home: {
    minute: 'minuto',
    price_info: '{mianfei} minutos gratis, máximo por día {currencyName}{fengding}, depósito {currencyName}{yajin}, depósito de alquiler gratuito',
    submit: 'Recargar',
    borrow: 'Pedir prestado',
    msg_rentcount: 'Actualmente tienes {zujieNum} pedidos que no están cerrados',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    msg_fail_title: 'Error',
    msg_fail_content: 'El alquiler falló. Inténtalo de nuevo.',
    offline: 'El dispositivo {devicecode} está fuera de línea, comuníquese con el personal presente',
    permissionfail: 'Permiso denegado.',
    unbound: 'El código QR mixto {devicecode} no está vinculado al dispositivo',
    nobattery: 'No hay batería disponible en el dispositivo {código de dispositivo}',
    next: 'SIGUIENTE',
    renttext: 'Alquile un banco de energía'
  },
  loginborrow: {
    cCard: 'Tarjeta de crédito',
    perhour: 'hora',
    maxperday: 'día',
    preauth: 'Preautorización',
    enterpromocode: 'Ingrese el código de promoción',
    powerbanktitle: 'Inicia sesión para desbloquear tu banco de energía',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error al obtener información del usuario. ¿Quieres intentarlo de nuevo?',
    msg_rentcount: 'Actualmente tienes {zujieNum} pedidos que no están cerrados',
    msg_fail_support: 'El sistema no es compatible',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Rever',
    msg_fail_cancel: 'Cancelar',
    msg_fail_order: 'No se pudo crear la orden de arrendamiento',
    msg_fail_paymod: 'No se pudo obtener el método de pago',
    msg_fail_login: 'error de inicio de sesion',
    Apple: 'Manzana',
    otheropt: 'Otras opciones',
    selectmethod: 'selecciona tu forma de pago',
    paymentdetail: 'Detalles del método de pago',
    couponcode: 'Código promocional',
    rent: 'Confirmar pago',
    pricing: 'Ver precios',
    phone: 'Teléfono',
    Google: 'Google',
    Facebook: 'Facebook',
    loginfb: 'Iniciar sesión usando Facebook',
    logingg: 'Inicia sesión con Google',
    captcha_code: 'Código',
    phonenumber: 'Ingrese su número telefónico',
    msg_fail_code: 'porfavor ingrese el codigo de verificacion',
    msg_fail_phone: 'Por favor, introduzca su número de teléfono',
    logout: 'Salir de la cuenta',
    savenow: 'Guardar ahora',
    paynow: "Pago inmediato",
    delete: 'Borrar',
    addcard: 'Introduce los datos de la tarjeta',
    msg_fail_content: 'El alquiler falló. Inténtalo de nuevo.',
    title: 'Por favor confirme la autorización',
    policy: 'Política.',
    term: '  Términos y condiciones',
    and: 'y',
    privacy: 'Política de privacidad.',
    tips: 'Al continuar aceptas nuestra',
    cancel: 'CANCELAR',
    agree: 'ACEPTAR',
    balance: 'equilibrioe'
  },
  preauth: {
    preauthortitle: 'Sobre la preautorización',
    tip1: 'Al iniciar un alquiler, se realiza una preautorización en su método de pago. Esto es sólo para garantizar que haya fondos suficientes disponibles para pagar el alquiler.',
    tip2: 'Puede que tu banco te envíe una notificación pero no es un cargo: sólo se cobrará el precio de tu alquiler.',
    tip3: 'Dependiendo de su banco, el ajuste del monto en sus extractos puede demorar desde algunas horas hasta algunos días, esto es normal..'
  },
  rentcost: {
    free: "Minutos libres 3min",
    per: 'por',
    min: 'mínimo',
    charged: 'Se le cobrará',
    rentcosttitle: '¿Cuánto cuesta el alquiler?',
    tip1: 'Si no devuelve el banco de energía dentro de las 48 horas, se le cobrará una tarifa por no devolución de {currencyName} {yajin}.',
    tip2: 'La duración se redondea a la media hora más cercana (30 o 60 minutos).',
    tip3: 'El alquiler de un día es de 24 horas desde que se contrata la power bank.',
    tip3_1: 'Se aplicará una tarifa de autorización previa de {currencyName} {cyajin} a su método de pago. Esto es para garantizar que haya fondos suficientes disponibles para pagar el alquiler.',
    tip3_2: 'Tu banco puede enviarte una notificación pero no es un cargo; Sólo se cobrará el precio de su alquiler.',
    tip3_3: 'Dependiendo de su banco, pueden pasar algunas horas o hasta algunos días hasta que se ajuste el monto en sus extractos.',
    tip4: 'Algunos lugares pueden ofrecer algunos horarios gratuitos o descuentos. ¡Disfrutar!',
    Days: 'Días',
    Hours: 'Horas',
    Minutes: 'Minutos'
  },
  logincode: {
    login: 'Acceso',
    retry: 'Reenviar',
    title: 'Código de verificación',
    subtitle: 'Por favor ingresa el código de verificación que te enviamos a tu número de teléfono.r'
  },
  agreenconfirm: {
    title: 'Por favor confirme la autorización',
    policy: 'Política.',
    term: "PowerBox's Terms & Conditions",
    and: 'y',
    privacy: 'Política de privacidad.',
    tips: 'Estoy de acuerdo con',
    cancel: 'CANCELAR',
    agree: 'DE ACUERDOE'
  },
  login: {
    phonenumber: 'Número de teléfono',
    verificationcode: 'Código de verificación',
    Rentpowerbank: 'Alquilar un banco de energía',
    Rentpowerbanktip: 'Ingresa tu número de celular para comenzar',
    phone_number: 'Introduce un número de móvil de {0} dígitos',
    captcha_code: 'Código',
    send_code: 'Enviar código',
    login: 'Acceso',
    agree: 'Al hacer clic en Iniciar sesión, aceptas',
    readme: `User Agreement`,
    msg_fail_login: 'error de inicio de sesion',
    msg_success_login: 'Inicio de sesión correcto',
    msg_success_login_code: 'Recibido exitosamente, descargue la APLICACIÓN para iniciar sesión y usarla',
    msg_fail_user: 'Error al obtener información del usuario. ¿Quieres intentarlo de nuevo?',
    no_sms: 'No recibí mensaje de texto',
    retry: 'Reenviar',
    receive: 'Aplicar',
    invite_pwd: 'Código de invitación',
    vip_agreen_tip: 'Al continuar estás indicando que aceptas nuestra',
    vip_agreen_service: 'Términos de servicio',
    vip_agreen_and: 'y',
    vip_agreen_policy: 'Política de Privacidady'
  },
  confirm: {
    title: 'Confirmación de pago',
    pay_label: 'Cantidad',
    addcard: 'Agregar tarjeta de billetera',
    price_tips: 'Haga clic para confirmar el pago, indicando que ha leído y aceptado las condiciones',
    price_btn: '《Acuerdo de valor agregado》',
    pay_type: 'Por favor seleccione su método de pago preferido',
    submit: 'Confirmar contrato de arrendamiento',
    charge: 'Confirmar recarga',
    msg_fail_title: 'Advertencia',
    msg_fail_order: 'No se pudo crear la orden de arrendamiento',
    msg_fail_confirm: 'Confirmar',
    msg_fail_retry: 'Rever',
    msg_fail_cancel: 'cáncerl'
  },
  mine: {
    title: 'Perfil del usuario',
    wallet: 'Mi billetera',
    balance: 'Mi balance',
    mywalletcard: 'Mi tarjeta',
    addcard: 'Agregar tarjeta de billetera',
    deposit: 'Depósito',
    wallet_in: 'Cargar',
    wallet_out: 'Retirar',
    wallet_log: 'Registro de billetera',
    order: 'Orden',
    login_tips: 'Por favor Iniciar sesión',
    msg_fail_title: 'Error',
    msg_fail_user: 'Error al obtener información del usuario. ¿Quieres intentarlo de nuevo?',
    help: 'Ayuda',
    setting: 'Configuración',
    logout: 'Cerrar sesión',
    unpaid: 'No pagado',
    using: 'En nosotrose'
  },
  msg: {
    oneOrdering:
      "1 La orden está en progreso, y el contrato de arrendamiento no se puede continuar por el momento.",
    nopaymode: 'El método de pago no pudo obtener',
    nopaymode: 'Falló la adquisición del método de pago',
    unlogin: 'Aún no has iniciado sesión, por favor inicia sesión',
    msg_fail_title: 'Advertencia',
    deletecard: '¿Estás seguro de eliminar esta tarjeta?',
    loading: 'Cargando...',
    title: 'Consejos',
    success: 'Operación exitosa',
    error: 'Error desconocido, inténtalo de nuevo más tarde.',
    userinfo_fail: 'La información del usuario no es válida, inicie sesión nuevamente',
    roleerror: 'Permisos insuficientes, consulte al administrador.',
    neterror: 'La conexión de red falló. Por favor, inténtelo de nuevo más tarde',
    confirm: 'Confirmar',
    retry: 'Rever',
    cancel: 'Cancelar',
    msg_logout: '¿Estás seguro de salir de la cuenta?',
    vieworder: 'Ver pedido',
    addorselectcard: 'Por favor agregue o seleccione un paquete de tarjeta para continuar',
    success_payment: 'Pago completado',
    fail_payment: 'Fallo de pago',
    keyword: 'palabra clave',
    unpaid_order: 'Hay pedidos impagos',
    go_pay: 'ir a pagar',
    no_more: 'no más',
    noshop: 'Sin comerciantes',
    msg_fail_code: 'porfavor ingrese el codigo de verificacion',
    msg_fail_phone: 'Por favor, introduzca su número de teléfono',
    msg_fail_orderid: 'Por favor introduzca el número de pedido',
    msg_fail_email: 'El correo electrónico no puede estar vacío.',
    exceedorder: 'Ya tienes un pedido en arrendamiento, devuélvelo antes de continuar',
    nodevicecode: 'El código del dispositivo no existe',
    appoffline: 'La aplicación aún no se ha iniciado, espere',
    payment_unsupported: 'Este método de pago no es compatible',
    msg_fail_balance: 'Saldo de usuario insuficiente',
    msg_fail_order_balance: 'El pedido no existe o el saldo es insuficientet'
  },
  result: {
    title: '¡Banco de energía desbloqueado!',
    tips: '¡Tu banco de energía ya está listo para que puedas cargarlo mientras viajas!',
    download: 'Descargar',
    msg_result_loading: 'Se está confirmando el resultado del pago...',
    msg_tips_loading: 'La batería portátil está apareciendo, espere pacientemente...',
    haveorder: 'Se ha generado el pedido oficial.d'
  },
  result_msg: {
    loading: 'Cargando...',
    refresh: 'Refrescah'
  },
  order: {
    title: 'Orden de arrendamiento',
    in_use: 'En uso',
    ended: 'Terminado',
    msg_error: 'Solicitud fallida',
    no_data: 'Sin fechaa'
  },
  order_item: {
    order_number: 'No.',
    venue_rented: 'Local alquilado',
    time_rented: 'tiempo alquilado',
    venue_returned: 'Lugar devuelto',
    time_returned: 'El tiempo regresó',
    charge_standard: 'Estándar de carga',
    rent_occurred: 'El alquiler ocurrió',
    returned: 'Devuelto',
    unreturned: 'No devuelto',
    type_0: 'Sin completar',
    type_1: 'Alquiler',
    type_2: 'Cancelado',
    type_3: 'Devuelto',
    type_4: 'Se produjo una falla',
    type_5: 'Con el tiempo',
    min: 'mín.',
    unpaid: 'No pagado',
    payment: 'Pago'
  }
}
